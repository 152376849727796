import React from 'react'
import downloadLabel from '../../utils/downloadLabel'
import { checkWhite } from '../../constants/icons'

const ModalCloseReturn = ({
  manifest,
  setManifest,
  setPickProduct,
  setClosedPackages,
  styleText
}) => {
    const onPrint = () => {
        downloadLabel(manifest)

    }

    const onClose = () => {
        setManifest('')
        setPickProduct([])
        setClosedPackages([])
    }

    return(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
      <div className="w-full max-w-sm rounded-lg bg-white p-6 shadow-lg">
        <div className="flex flex-col items-center">
          <div className="mb-4 rounded-full bg-[#34c240]">
          <img src={checkWhite} alt="check" className='h-16 w-16' />

          </div>
          <h2 className="mb-2 text-center text-xl font-semibold">
            Retorno creado correctamente
          </h2>
          <p className={`${styleText} mb-4 text-center text-sm text-gray-600`}>
            Se configuró correctamente el retorno. <br />
            ¿Deseas imprimir la guía de despacho asignada?
          </p>
          <div className="flex w-full flex-col gap-2">
            <button
            type='button'
              onClick={onPrint}
              className="rounded-lg bg-[#33cccc] px-4 py-2 text-white shadow hover:bg-[#33cccc]/100"
            >
              Imprimir
            </button>
            <button
            type='button'
              onClick={onClose}
              className="rounded-lg bg-white px-4 py-2 text-gray-700 shadow hover:bg-gray-100"
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  )}

export default ModalCloseReturn
