import { useContext, useState } from 'react'

import getSkuInfo from '../helpers/requests/getSkuInfo'
import { AuthContext } from '../contexts/AuthContext'

const useProductManagement  = () => {
  const [pickProduct, setPickProduct] = useState([])
  const [closedPackages, setClosedPackages] = useState([])
  const [newProduct, setNewProduct] = useState({ barcode: '', name: '', price: '' })
  const [isLoading, setIsLoading] = useState(false)
  const { apiKey } = useContext(AuthContext)


  const fetchProductData = async (barcode) => {
    setIsLoading(true)
    try {
      const productData = await getSkuInfo(apiKey, barcode)

      if (productData && productData.name !== 'Unknown') {
        const { name, price } = productData
        setPickProduct((prevPickProduct) => [
          ...prevPickProduct,
          { barcode, name, price, quantity: 1 }
        ])
        setIsLoading(false)
      } else {
        setIsLoading(false)
        setNewProduct({ barcode, name: '', price: '' })
      }
    } catch (error) {
      setIsLoading(false)
      setNewProduct({ barcode, name: '', price: '' })
    }
  }

  // eslint-disable-next-line react-func/max-lines-per-function
  const searchProduct = async (barcode) => {
    setNewProduct({ barcode: '', name: '', price: '' })
    const existingProduct = pickProduct.find((p) => p.barcode === barcode)
    if (existingProduct) {
      setPickProduct((prevPickProduct) =>
        prevPickProduct.map((p) => (p.barcode === barcode ? { ...p, quantity: p.quantity + 1 } : p))
      )
      return
    }

    const existingInClosedPackages = closedPackages.find((pkg) =>
      pkg.products.find((p) => p.barcode === barcode)
    )
    if (existingInClosedPackages) {
      const productFromClosedPackage = existingInClosedPackages.products.find(
        (p) => p.barcode === barcode
      )
      setPickProduct((prevPickProduct) => [
        ...prevPickProduct,
        { ...productFromClosedPackage, quantity: 1 }
      ])
      return
    }

    await fetchProductData(barcode)
  }

  const handleNewProductChange = (e) => {
    const { name, value } = e.target
    setNewProduct({ ...newProduct, [name]: value })
  }

  const addNewProduct = async (product) => {
    const validPrice = Number.isNaN(Number(product.price)) || product.price === '' ? 0 : product.price

    setPickProduct((prevPickProduct) => [
      ...prevPickProduct,
      { ...product, price: validPrice, quantity: 1 }
    ])

    setNewProduct({ barcode: '', name: '', price: '' })
  }

  const handleAddNewProduct = () => {
    if (!newProduct.name || !newProduct.price || !newProduct.barcode) {
      return
    }
    addNewProduct(newProduct)
  }

  const updateQuantity = (barcode, change) => {
    setPickProduct((prevPickProduct) =>
      prevPickProduct.map((p) =>
        p.barcode === barcode ? { ...p, quantity: Math.max(p.quantity + change, 1) } : p
      )
    )
  }

 const removeProduct = (barcode) => {
   setPickProduct((prevPickProduct) => prevPickProduct.filter((p) => p.barcode !== barcode))
 }

  return {
    pickProduct,
    setPickProduct,
    newProduct,
    setNewProduct,
    closedPackages,
    setClosedPackages,
    searchProduct,
    addNewProduct,
    updateQuantity,
    removeProduct,
    handleAddNewProduct,
    handleNewProductChange,
    isLoading
  }
}

export default useProductManagement

