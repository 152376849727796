import apiClient from '../../axiosConfig/apiClient'
import { MANIFEST_CREATE_URL, GET_MANIFEST, GET_MANIFEST_BY_ID } from '../../constants/api'

const createManifest = async (body) => {
  const response = await apiClient.put(MANIFEST_CREATE_URL, body)
  return response.data
}

const buildManifestBody = (shippingsData) => ({
  'addedShippings': shippingsData.trackings,
  'courier': shippingsData.courier,
  'packageType': 'distribution-return',
  warehouseId: shippingsData.warehouseId

})

export const generateManifest = async (shippingsData) => {
    try {
      const data = await createManifest(buildManifestBody(shippingsData))

      return data.manifestPdf

    } catch (error) {
      return null
    }
  }

export const getManifest = async () => {
  const response = await apiClient.get(GET_MANIFEST, {params: {packageType: 'distribution-return'}})
  return response.data
}


export const getManifestById = async (manifestId) => {
  const response = await apiClient.get(GET_MANIFEST_BY_ID(manifestId))
  return response.data
}
