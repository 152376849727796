import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { getPackageInformation } from '../../helpers/requests/packagesInformation'
import SearchBar from '../../components/shared/SearchBar'
import Header from '../../components/Header'
import { PACKAGE_STATUS_MANIFEST } from '../../constants/packageStatus'
import ROUTES from '../../constants/routes'

const ManifestPackage = () => {
  const { manifestId, packageId } = useParams()
  const [infoPackage, setInfoPackage] = useState({})
  const { isLoading, error } = useFetch(getPackageInformation, setInfoPackage, packageId)
  const [searchQuery, setSearchQuery] = useState('')

  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <div className="h-8 w-8 animate-spin rounded-full border-4 border-teal-500 border-t-transparent" />
      </div>
    )
  }

  if (error) {
    return <div className="w-full rounded-lg">Error al cargar la información: {error.message}</div>
  }
  const handleSearch = (e) => {
    e.preventDefault()
  }

  return (
    <div className="w-full bg-gray-100">
      <Header
        to={`${ROUTES.MANIFESTS}/${manifestId}`}
        name="Manifiestos"
        color="bg-purple"
        qrScannerStyle
      />
      <div className="mx-auto mt-20 w-11/12">
        <div className="mb-6 rounded-lg bg-white p-6 shadow-sm">
          <div className="flex items-center justify-between border-b pb-4">
            <div>
              <div className="flex items-center gap-3">
                <h2 className="text-xl font-medium">Paquete: {infoPackage.packageId}</h2>
                <span
                  className={`${PACKAGE_STATUS_MANIFEST[infoPackage.packageStatus]?.style}
                  flex flex-row items-center rounded-full px-3 py-1`}
                >
                  <img
                    src={PACKAGE_STATUS_MANIFEST[infoPackage.packageStatus]?.icon}
                    alt={infoPackage.packageStatus}
                    className="mr-1 h-4 w-4"
                  />
                  {PACKAGE_STATUS_MANIFEST[infoPackage.packageStatus]?.text}
                </span>
              </div>
              <div className="mt-2 flex items-center gap-2 text-sm text-gray-600">
                <span className="rounded-full bg-gray-100 px-2 py-1">
                  {infoPackage.products?.length || 0} productos
                </span>
              </div>
            </div>

          </div>

          <div className="mx-2 mt-4 flex flex-row justify-between">
            <div className="mb-6">
              <h3 className="text-lg font-medium">{infoPackage.customer?.nombrepersona}</h3>
            </div>

            <div className="flex gap-10">
              <div>
                <p className="text-sm text-gray-500">Fecha entrega</p>
                <p className="font-medium text-gray-500">-</p>
              </div>
              <div>
                <p className="text-left text-sm text-gray-500">Servicio</p>
                <p className="text-left font-medium text-gray-500">-</p>
              </div>
              <div>
                <p className="text-left text-sm text-gray-500">O. Transporte</p>
                <p className="text-left font-medium text-gray-500">{infoPackage.orderId}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="rounded-lg bg-white p-6 shadow-sm">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-medium">Productos</h3>
            <div className="flex items-center gap-3">
              <SearchBar
                placeholder="Buscar"
                setter={setSearchQuery}
                getter={searchQuery}
                searcher={handleSearch}
              />
              <button type="button" className="rounded-lg border p-2 hover:bg-gray-50">
                <span className="sr-only">Filtrar</span>⋮
              </button>
            </div>
          </div>

          <div className="overflow-hidden rounded-lg border">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr className="text-left text-sm text-gray-500">
                  <th className="px-4 py-3 text-left">Nombre</th>
                  <th className="px-4 py-3 text-left">SKU</th>
                  <th className="px-4 py-3 text-left">Cantidad</th>
                </tr>
              </thead>
              <tbody className="divide-y">
                {infoPackage.products?.map((product) => (
                  <tr key={product.sku} className="hover:bg-gray-50">
                    <td className="px-4 py-3 text-left">{product.name}</td>
                    <td className="px-4 py-3 text-left text-gray-600">{product.sku}</td>
                    <td className="px-4 py-3 text-left">{product.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManifestPackage
