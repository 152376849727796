import React, { useMemo, useState } from 'react'


export const MenuContext = React.createContext()

const MenuProvider = ({ children }) => {
  const [isDistribution, setIsDistribution] = useState(false)

  const menuState = useMemo(() => ({
    isDistribution,
    setIsDistribution
  }), [isDistribution])

  return <MenuContext.Provider value={menuState}>{children}</MenuContext.Provider>
}

export default MenuProvider
