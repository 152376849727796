import React from 'react'

const NewProductForm = ({ newProduct, handleNewProductChange, handleAddNewProduct, styleText }) => (
  <section>
    <h2 className={styleText}>Barcode {newProduct.barcode} no encontrado, ingresa:</h2>
    <div className="flex flex-col gap-8 p-2 sm:m-1 md:flex-row lg:mx-6">
      <input
        type="text"
        name="name"
        placeholder="Nombre del producto"
        value={newProduct.name}
        onChange={handleNewProductChange}
      />
      <input
        type="text"
        name="price"
        placeholder="Precio"
        value={newProduct.price}
        onChange={handleNewProductChange}
      />
      <button className='rounded-full border-2 bg-[#33cccc] px-4 py-2 text-white' type="button" onClick={handleAddNewProduct}>
        Agregar
      </button>
    </div>
    </section>
  )

export default NewProductForm
