import React from 'react'

const StatsDisplay = ({ pickProduct, closedPackages, styleText }) => {
  const calculateTotalProducts = () => {
    const openPackageTotal = pickProduct.reduce((total, p) => total + p.quantity, 0)
    const closedPackagesTotal = closedPackages.reduce(
      (total, pkg) => total + pkg.products.reduce((pkgTotal, p) => pkgTotal + p.quantity, 0),
      0
    )
    return openPackageTotal + closedPackagesTotal
  }

  const calculateTotalSKUs = () => {
    const openSKUs = pickProduct.map((p) => p.barcode)
    const closedSKUs = closedPackages.flatMap((pkg) => pkg.products.map((p) => p.barcode))
    const uniqueSKUs = new Set([...openSKUs, ...closedSKUs])
    return uniqueSKUs.size
  }

  return (
    <div className="mx-auto mt-4 flex flex-row gap-4">
      <h2 className={`${styleText}`}>Paquetes: {closedPackages.length}</h2>
      <h2 className={`${styleText}`}>Productos: {calculateTotalProducts()}</h2>
      <h2 className={`${styleText}`}>SKUs: {calculateTotalSKUs()}</h2>
    </div>
  )
}

export default StatsDisplay
