import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import BottomNavbar from '../../components/BottomNavbar'
import Loader from '../../components/shared/Loader'
import PackageList from '../../components/shared/PackageList'
import SearchBar from '../../components/shared/SearchBar'
import { SEARCH_ICONS, whiteArrow } from '../../constants/icons'
import { getPackagesByStatus } from '../../helpers/requests/packagesInformation'
import useFetch from '../../hooks/useFetch'
import { isSubstring } from '../../utils/strings'
import FilterButton from '../../components/PackagesFilter/FilterButton'
import PackagesFilter from '../../components/PackagesFilter/PackagesFilter'
import {
  PACKAGE_DELIVERED,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_RECEIVED,
  PACKAGE_STATUS_FILTER,
  PACKAGE_STORED
} from '../../constants/packageStatus'

const SearchPackages = () => {
  const [packagesInfo, setPackagesInfo] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [searchResult, setSearchResult] = useState([])
  const [filterResult, setFilterResult] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [filtersAmount, setFiltersAmount] = useState(0)

  const setPackages = useCallback((packagesData) => {
    setPackagesInfo(packagesData)
    setSearchResult(packagesData)
  }, [])
  const statusToShow = [
    PACKAGE_IN_TRANSIT,
    PACKAGE_ON_DELIVERY,
    PACKAGE_RECEIVED,
    PACKAGE_STORED,
    PACKAGE_PICKED_UP,
    PACKAGE_DELIVERED
  ].join(',')

  const { isLoading, error } = useFetch(getPackagesByStatus, setPackages, statusToShow)

  const searchPackage = (event) => {
    event.preventDefault()
    const filteredPackages = packagesInfo.filter(
      (packageItem) =>
        isSubstring(packageItem.pinflagId, searchInput) ||
        isSubstring(packageItem.orderId, searchInput) ||
        isSubstring(packageItem.customerFullName, searchInput)
    )
    setSearchResult(filteredPackages)
  }

  const filterPackages = (selectedFilters) => {
    const filterStatus = PACKAGE_STATUS_FILTER.filter((_status, index) =>
      selectedFilters.includes(index)
    ).flatMap((status) => status.status)

    const filteredPackages = searchResult.filter((packageItem) =>
      filterStatus.includes(packageItem.packageStatus)
    )

    setFiltersAmount(selectedFilters.length)
    setFilterResult(filteredPackages)
  }

  const packagesToShow = filtersAmount > 0 ? filterResult : searchResult

  return (
    <>
      <div className="flex w-screen flex-col items-center justify-center">
        <div className="fixed top-0 flex w-screen flex-col items-center rounded-b-3xl bg-pinflag-dark px-5 py-2 text-white">
          <div className=" flex w-full items-center justify-items-start">
            <Link to="/" className="p-4">
              <img className="w-6" src={whiteArrow} alt="Volver" />
            </Link>
            <div className="mx-auto pr-6 text-xl font-semibold">Pedidos</div>
          </div>
        </div>
        <div className='fixed top-28 w-96'>
        <SearchBar
            placeholder="Busca un pedido..."
            setter={setSearchInput}
            getter={searchInput}
            searcher={searchPackage}
            filter={
              <FilterButton
                filtersAmount={filtersAmount}
                openFilters={() => setShowFilters(!showFilters)}
              />
            }
          />
          </div>
        <PackagesFilter
          show={showFilters}
          handleClose={() => setShowFilters(false)}
          filterPackages={filterPackages}
        />
        <div className="mt-44 mb-20 flex h-screen w-full max-w-4xl flex-col gap-y-5 overflow-y-auto p-10 lg:mb-10">
          {isLoading && !error && <Loader />}
          {error && <div>Ha ocurrido un error. Inténtelo de nuevo más tarde.</div>}
          {packagesInfo.length !== 0 && packagesToShow.length === 0 && !isLoading && (
            <div className="m-auto flex flex-col gap-1">
              <img className="mx-auto mb-2 w-20" src={SEARCH_ICONS.noFilteredPackages} alt="" />
              <div className="text-lg text-medium-gray">No hay pedidos</div>
              <div className="text-sm text-medium-gray">que coincidan con la búsqueda</div>
            </div>
          )}
          {packagesInfo.length === 0 && !isLoading && (
            <div className="m-auto flex flex-col gap-1">
              <img className="mx-auto mb-2 w-20" src={SEARCH_ICONS.noPackages} alt="" />
              <div className="text-lg text-medium-gray">No hay pedidos</div>
              <div className="text-sm text-medium-gray">para mostrar</div>
            </div>
          )}
          {!isLoading && !error && packagesToShow.length > 0 && (
            <PackageList packagesInfo={packagesToShow} statusIcon statusText detail name />
          )}
        </div>
      </div>
      <BottomNavbar />
    </>
  )
}

export default SearchPackages
