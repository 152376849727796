import React, { useState } from 'react'
import NewProductForm from './NewProductForm'
import { arrowup, arrowdown, SEARCH_ICONS, dots, deleted, manifest } from '../../constants/icons'
import downloadLabel from '../../utils/downloadLabel'
import Loader from '../shared/Loader'
import GuideNumberInput from './GuideNumberInput'
import PickManualProduct from './PickManualProduct'

const PackageContainer = ({
  pickProduct,
  packageData,
  newProduct,
  updateQuantity,
  handleAddNewProduct,
  handleNewProductChange,
  styleText,
  remove,
  isLoading,
  isOpenPackage,
  guideNumber,
  setGuideNumber,
  showInput,
  setInput,
  setPickProduct
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenPackage)
  const [show, setShow] = useState(false)
  const getTotalProducts = () => pickProduct.reduce((total, product) => total + product.quantity, 0)
  const getTotalPrice = () =>
    pickProduct.reduce((total, product) => total + product.price * product.quantity, 0)

  const reprintLabel = () => {
    setShow((showButton) => !showButton)
  }

  return (
    <section>
      <div className="mx-auto mt-2 flex w-full flex-col rounded-md bg-white py-1">
        <div className="relative flex w-full">
          {!isOpenPackage && (
            <button type="button" onClick={reprintLabel} className="mx-2 w-[3%] p-0">
              <img src={dots} alt="dots" />
            </button>
          )}
          {show && packageData?.label && (
            <div className="absolute left-[-170px] top-full z-10 mt-1 min-w-[200px] rounded-md border border-gray-200 bg-white shadow-lg">
              <button
                className="flex w-full items-center p-2 text-left text-gray-700 hover:bg-gray-50"
                type="button"
                onClick={() => {
                  downloadLabel(packageData?.label)
                  setShow(false)
                }}
              >
                <span className="gap-1">
                  <img src={manifest} alt="manifest" className="h-4 w-4" />
                </span>
                Reimprimir etiqueta
              </button>
            </div>
          )}
          <button
            type="button"
            className="flex w-full items-center justify-between rounded-t-md bg-white px-4 py-2 text-black"
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            <span className={styleText}>{packageData?.order_id}</span>
            {packageData?.tracking_number && (
              <span className="flex flex-row gap-1">
                <p className={styleText}>OT:</p>
                {packageData.tracking_number}
              </span>
            )}
            {!isAccordionOpen && (
              <span className="flex flex-row gap-1">
                <p className={styleText}>Productos:</p>
                {getTotalProducts()}
              </span>
            )}
            {!isAccordionOpen && (
              <span className="flex flex-row gap-1">
                {' '}
                <p className={styleText}>SKUs:</p> {pickProduct.length}
              </span>
            )}
            {isAccordionOpen ? (
              <img src={arrowdown} alt="arrow" />
            ) : (
              <img src={arrowup} alt="arrow" />
            )}
          </button>
        </div>
        {isAccordionOpen && (
          <section className="m-4 flex flex-col gap-1">
            <div className={`mx-6 flex flex-col items-start ${styleText}`}>
              <div className="flex items-start">
                {packageData?.guideNumber ? (
                  <p className="p-2">
                    Número de Guía: <span>{packageData.guideNumber}</span>
                  </p>
                ) : (
                  <GuideNumberInput
                    guideNumber={guideNumber}
                    setGuideNumber={setGuideNumber}
                    showInput={showInput}
                    setInput={setInput}
                    isOpenPackage
                  />
                )}
              </div>
            </div>
            {pickProduct.map((product) => (
              <div
                key={product.barcode}
                className="flex flex-row justify-around gap-2 bg-gray-100 p-2"
              >
                <div className=" flex flex-row gap-3">
                  <button
                    type="button"
                    className="bg-white px-2"
                    onClick={() => updateQuantity(product.barcode, -1)}
                    disabled={!isOpenPackage}
                  >
                    -
                  </button>
                  <p>{product.quantity}</p>
                  <button
                    type="button"
                    className="bg-white px-2"
                    onClick={() => updateQuantity(product.barcode, 1)}
                    disabled={!isOpenPackage}
                  >
                    +
                  </button>
                  <div />
                </div>
                <div
                  className="w-20 overflow-hidden whitespace-nowrap text-start"
                  title={product.price.toString()}
                >
                  ${parseFloat(product.price).toLocaleString('es-ES')}
                </div>
                <div
                  className="w-48 overflow-hidden whitespace-nowrap text-start"
                  title={product.name}
                >
                  {product.name}
                </div>
                <div
                  className="w-32 overflow-hidden whitespace-nowrap text-start"
                  title={product.barcode.toString()}
                >
                  {product.barcode}
                </div>
                {isOpenPackage && (
                  <button type="button" onClick={() => remove(product.barcode)}>
                    <img src={deleted} alt="deleted" />
                  </button>
                )}
              </div>
            ))}
            {isLoading && <Loader />}
            {isOpenPackage &&
              newProduct.barcode &&
              !pickProduct.find((p) => p.barcode === newProduct.barcode) && (
                <NewProductForm
                  newProduct={newProduct}
                  handleNewProductChange={handleNewProductChange}
                  handleAddNewProduct={handleAddNewProduct}
                  styleText={styleText}
                />
              )}

            {pickProduct.length === 0 && isAccordionOpen && (
              <div className=" flex items-center justify-center gap-2 rounded-3xl border-2 border-dashed border-[#33cccc] bg-[#f6ffff] p-8 text-[#33cccc] sm:m-2 lg:m-6">
                <img src={SEARCH_ICONS.barcode} alt="barcode" className="h-7 w-7" />
                Pickea los productos
              </div>
            )}
            {isOpenPackage && <PickManualProduct setPickProduct={setPickProduct} />}

            <div className="my-2 flex flex-row justify-between sm:mx-2 sm:gap-6 lg:mx-8 lg:gap-16">
              <h2 className={`${styleText}`}>Productos: {getTotalProducts()}</h2>
              <h2 className={`${styleText}`}>Precio: ${getTotalPrice().toLocaleString('es-ES')}</h2>
              <h2 className={`${styleText}`}>SKUs: {pickProduct.length}</h2>
            </div>
          </section>
        )}
      </div>
    </section>
  )
}

export default PackageContainer
