import { useEffect, useRef, useCallback } from 'react'

// eslint-disable-next-line react-func/max-lines-per-function
const BarcodeInput = ({ onSearch, resetBuffer, isLoading }) => {
  const barcodeBuffer = useRef('')
  const debounceTimeout = useRef(null)
  const typingTimeout = 100

  const processBarcode = useCallback(() => {
    const sanitizedBarcode = barcodeBuffer.current.trim()

    if (sanitizedBarcode) {
      onSearch(sanitizedBarcode)
    }

    barcodeBuffer.current = ''
    if (resetBuffer) resetBuffer()
  }, [onSearch, resetBuffer])

  const handleKeyPress = useCallback(
    (e) => {
      if (isLoading) return

      const { activeElement } = document
      const tagName = activeElement.tagName.toLowerCase()

      if (tagName === 'input' || tagName === 'textarea' || activeElement.isContentEditable) {
        return
      }

      if (e.key === 'Enter') {
        processBarcode()
        return
      }

      barcodeBuffer.current += e.key

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = setTimeout(processBarcode, typingTimeout)
    },
    [isLoading, processBarcode, typingTimeout]
  )

  useEffect(() => {
    barcodeBuffer.current = ''
    window.addEventListener('keypress', handleKeyPress)

    return () => {
      window.removeEventListener('keypress', handleKeyPress)

      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }
    }
  }, [handleKeyPress])

  return null
}

export default BarcodeInput
