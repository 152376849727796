import cmsAdapterClient from '../../axiosConfig/cmsClient'
import { GET_SKU } from '../../constants/api'


const getSkuInfo = async (apiKey, sku) => {
  const response = await cmsAdapterClient.get(GET_SKU, {
    params: { apiKey, sku }
  })
  return response.data
}

export default getSkuInfo
