import React from 'react'

const GuideNumberInput = ({ guideNumber, setGuideNumber, isOpenPackage, showInput, setInput }) => {
  const handleInputChange = (event) => {
    setGuideNumber(event.target.value)
  }

  const handleSave = () => {
    setGuideNumber(guideNumber)
    setInput(false)
  }

  return (
    <div className="sm:m-2">
      {isOpenPackage && showInput ? (
        <div className="flex items-center gap-3">
          <label className="font-medium text-gray-700">
            Número de Guía:
          </label>
          <input
            type="text"
            className="flex-1 rounded-lg border border-gray-300 px-3 py-2 text-gray-700 shadow-sm placeholder:text-gray-400 focus:border-[#33cccc] focus:outline-none"
            value={guideNumber}
            onChange={handleInputChange}
            placeholder="Escribe el número de guía"
          />
          <button
            type="button"
            onClick={handleSave}
            className="rounded-lg bg-[#33cccc] px-4 py-2 text-white transition-colors duration-200 hover:bg-[#2eb8b8]"
          >
            Guardar
          </button>
        </div>
      ) : (
        <div className="py-2">
          <span className="font-medium text-gray-700">
            Número de Guía:{' '}
          </span>
          <span className="text-gray-900">
            {guideNumber}
          </span>
        </div>
      )}
    </div>
  )
}

export default GuideNumberInput
