import React, { useContext, useState } from 'react'
import { generateManifest } from '../../helpers/requests/manifest'
import { AuthContext } from '../../contexts/AuthContext'

const CloseReturnButton = ({ inactive, setIsCreating, closedPackages, setManifest }) => {
  const [isCreatingManifest, setIsCreatingManifest] = useState(false)
  const {warehouseId} = useContext(AuthContext)

  const closeReturn = async () => {
    setIsCreating(true)
    setIsCreatingManifest(true)
    const trackings = closedPackages.map((pkg) => pkg.tracking_number)
    const courier = closedPackages.length > 0 ? closedPackages[0].courier : 'blueexpress'
    const manifestPdf = await generateManifest({trackings, warehouseId, courier})
    setManifest(manifestPdf)
    setIsCreating(false)
    setIsCreatingManifest(false)
  }

  return (
    <button
      className={`mt-20 rounded-full border-2 p-3 text-black ${
        inactive ? 'bg-[#e0e0e0]' : 'border-black bg-[#cecece]'
      } `}
      type="button"
      onClick={closeReturn}
      disabled={inactive}
    >
      {isCreatingManifest ? 'Creando Manifiesto' : 'Cerrar Retorno'}
    </button>
  )
}

export default CloseReturnButton
