import React, { useState } from 'react'
import distributionReturnPackage from '../../helpers/requests/distributionReturnPackage'
import { closedPackage } from '../../constants/icons'

const ClosePackageButton = ({
  inactive,
  setIsCreating,
  pickProduct,
  setPickProduct,
  setClosedPackages,
  guideNumber,
  setGuideNumber,
  setInput
}) => {
  const [isCreateLabel, setIsCreateLabel] = useState(false)

  const addClosedPackage = (packageData) => {
    setClosedPackages((prevClosedPackages) => [
      ...prevClosedPackages,
      {
        products: pickProduct,
        label: packageData.label,
        tracking_number: packageData.tracking_number,
        order_id: packageData.order_id,
        courier: packageData.courier,
        guideNumber
      }
    ])
  }

  const closePackage = async () => {
    setIsCreating(true)
    setIsCreateLabel(true)

    const packageData = await distributionReturnPackage(pickProduct, guideNumber)
    if (packageData) addClosedPackage(packageData)

    setPickProduct([])
    setIsCreating(false)
    setIsCreateLabel(false)
    setGuideNumber('')
    setInput(true)
  }

  return (
    <button
    className={`mt-6 flex items-center justify-center gap-1 rounded-full border-2 p-3 text-white ${
      inactive ? 'bg-[#80e0e0]' : 'bg-[#33cccc]'
    }`}
    type="button"
    onClick={closePackage}
    disabled={inactive}
  >
    <img src={closedPackage} alt="package" className="h-6 w-6 brightness-0 invert" />
    {isCreateLabel ? 'Creando Etiqueta' : 'Cerrar Paquete'}
  </button>

  )
}

export default ClosePackageButton
