
import axios from 'axios'

import { CMS_ADAPTER_URL } from '../constants/api'

const cmsClient = axios.create({
    baseURL: CMS_ADAPTER_URL,
    timeout: 25000
  })

  export default cmsClient
