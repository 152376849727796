import React, { useState } from 'react'
import Header from '../../components/Header'
import BarcodeInput from '../../components/DistributionReturn/BarcodeInput'
import PackageContainer from '../../components/DistributionReturn/PackageContainer'
import StatsDisplay from '../../components/DistributionReturn/StatsDisplay'
import ClosePackageButton from '../../components/DistributionReturn/ClosePackageButton'
import CloseReturnButton from '../../components/DistributionReturn/CloseReturnButton'
import useProductManagement from '../../hooks/useProductManagement'
import ModalCloseReturn from '../../components/DistributionReturn/ModalCloseReturn'

const CreateReturnPackage = () => {
  const {
    pickProduct,
    setPickProduct,
    newProduct,
    closedPackages,
    setClosedPackages,
    searchProduct,
    updateQuantity,
    handleAddNewProduct,
    handleNewProductChange,
    removeProduct,
    isLoading
  } = useProductManagement()

  const [isCreating, setIsCreating] = useState(false)
  const [manifest, setManifest] = useState('')
  const [guideNumber, setGuideNumber] = useState('')
  const [isInputVisible, setIsInputVisible] = useState(true)


  const styleText = 'font-light text-zinc-600'

  return (
    <>
      <Header to="/" name="Retorno" color="bg-purple" qrScannerStyle />
      <div className="w-full overflow-y-auto bg-gray-100">
        <div className="my-20 mx-auto flex flex-col p-4 sm:w-full lg:w-6/12">
          <StatsDisplay
            pickProduct={pickProduct}
            closedPackages={closedPackages}
            styleText={styleText}
          />
          <BarcodeInput onSearch={searchProduct} isLoading={isLoading} />
          <h1 className="mt-8 flex justify-start">Paquetes</h1>
          <div className="mt-2">
            {closedPackages.map((pkg) => (
              <PackageContainer
                pickProduct={pkg.products}
                packageData={pkg}
                styleText={styleText}
                isOpenPackage={false}
              />
            ))}
          </div>
          <PackageContainer
            pickProduct={pickProduct}
            newProduct={newProduct}
            updateQuantity={updateQuantity}
            handleAddNewProduct={handleAddNewProduct}
            handleNewProductChange={handleNewProductChange}
            styleText={styleText}
            remove={removeProduct}
            isLoading={isLoading}
            guideNumber={guideNumber}
            setGuideNumber={setGuideNumber}
            showInput={isInputVisible}
            setInput={setIsInputVisible}
            setPickProduct={setPickProduct}
            isOpenPackage
          />
          <div className="flex flex-col">
            <ClosePackageButton
              inactive={isCreating || pickProduct.length < 1}
              setIsCreating={setIsCreating}
              pickProduct={pickProduct}
              setPickProduct={setPickProduct}
              setClosedPackages={setClosedPackages}
              guideNumber={guideNumber}
              setGuideNumber={setGuideNumber}
              setInput={setIsInputVisible}
            />
            <CloseReturnButton
              inactive={isCreating || manifest || closedPackages.length < 1}
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              closedPackages={closedPackages}
              manifest={manifest}
              setManifest={setManifest}
            />
            {manifest && (
              <ModalCloseReturn
                manifest={manifest}
                setManifest={setManifest}
                setPickProduct={setPickProduct}
                setClosedPackages={setClosedPackages}
                styleText={styleText}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateReturnPackage
