import React, { useState } from 'react'
import useFetch from '../../hooks/useFetch'
import { getManifest } from '../../helpers/requests/manifest'
import Header from '../../components/Header'
import ManifestTable from '../../components/Manifests/ManifestTable'
import Loader from '../../components/shared/Loader'
import SearchBar from '../../components/shared/SearchBar'
import Paginator from '../../components/shared/Paginator'

const ManifestsList = () => {
  const [manifestsList, setManifestsList] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [sortConfig, setSortConfig] = useState({ key: 'dynamicId', direction: 'desc' })
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(8)
  const { isLoading, error } = useFetch(getManifest, setManifestsList)

  const filteredManifests = manifestsList.filter(
    (manifest) =>
      manifest.dynamicId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      manifest.channel.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleSort = (key) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const sortedManifests = [...filteredManifests].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1
    }
    return 0
  })

  const handleSearch = (e) => {
    e.preventDefault()
  }

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = sortedManifests.slice(indexOfFirstItem, indexOfLastItem)

  return (
    <div className="w-full overflow-y-auto bg-gray-100">
      <Header to="/" name="Manifiestos" color="bg-purple" qrScannerStyle />
      {isLoading && <Loader />}
      {error && <div className="p-4 text-red">Error: {error}</div>}
      <div className="mx-auto mt-20 w-11/12 px-4 py-6">
        <div className="flex w-full justify-end bg-white p-4">
          <div className="w-2/5">
            <SearchBar
              placeholder="Buscar"
              setter={setSearchQuery}
              getter={searchQuery}
              searcher={handleSearch}
            />
          </div>
        </div>

        <ManifestTable
          manifestsList={currentItems}
          onSort={handleSort}
          sortConfig={sortConfig}
        />

        <Paginator
          totalItems={sortedManifests.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  )
}

export default ManifestsList
