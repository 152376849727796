import React from 'react'
import { Link } from 'react-router-dom'

const NotificationCard = ({ icon, route, title, subtitle, buttonText, number }) => (
  <div className="flex h-full w-full gap-x-4 rounded-md bg-white p-4">
    <div className="relative my-auto h-fit w-max rounded-xl bg-transparent-blue p-3">
      <img src={icon} alt="Notificacion" className="w-10" />
      {number > 1 && (
      <div className={`absolute right-[-0.5rem] top-[-1rem] rounded-full bg-pinflag-dark py-1.5 ${number > 9 ? 'px-2' : 'px-3'} text-sm text-white`}>
        {number > 99 ? '99+' : number}
      </div>
    )}
    </div>
    <div className="flex flex-col justify-between whitespace-nowrap text-left text-xxs sm:text-xs xs:text-xs">
      <div className="text-xs font-bold sm:text-sm xs:text-sm">{title}</div>
      <div className="mt-1 mb-3 font-light text-medium-gray">{subtitle}</div>
      <Link
        to={route}
        className="block w-24 max-w-full rounded-md bg-blue py-1 text-center text-white"
      >
        {buttonText}
      </Link>
    </div>
  </div>
)

export default NotificationCard
