import React from 'react'
import { Link } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import { triangle } from '../../constants/icons'

const ManifestTable = ({ manifestsList, onSort, sortConfig }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '-'
    return dateString.split(',')[0]
  }

  const tableHeaders = [
    { label: 'ID', key: 'dynamicId', className: 'text-left' },
    { label: 'Creación', key: 'closedAt', className: 'text-left' },
    { label: 'Courier', key: 'channel', className: 'text-left' }
  ]

  const tableKeys = ['dynamicId', 'closedAt', 'channel']

  const getArrowClasses = (key, direction) => {
    if (sortConfig.key === key) {
      return direction === 'asc' ? 'text-gray-400' : 'text-black'
    }
    return 'text-gray-300'
  }

  return (
    <div className="w-full overflow-x-auto bg-white">
      <div className="mx-auto w-2/5 border-collapse bg-white">
        <table className="mx-auto w-full border-collapse bg-white">
          <thead>
            <tr className="border-b">
              {tableHeaders.map((header) => (
                <th
                  key={header.key}
                  className={`p-4 font-medium text-gray-600 ${header.className} w-28`}
                  onClick={() => onSort(header.key)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="flex items-center gap-2">
                    {header.label}
                    <div className='flex flex-col gap-[1px]'>
                      <img
                        src={triangle}
                        alt="Sort indicator"
                        className={`h-1 w-2 transition-colors ${getArrowClasses(header.key, 'asc')}`}
                      />
                      <img
                        src={triangle}
                        alt="Sort indicator"
                        className={`h-1 w-2 rotate-180 transition-colors ${getArrowClasses(header.key, 'desc')}`}
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {manifestsList.map((manifest) => (
              <tr key={manifest.id} className="border-b hover:bg-gray-50">
                {tableKeys.map((key) => (
                  <td key={`${manifest.id}-${key}`} className="p-4 text-left">
                    <Link to={`${ROUTES.MANIFESTS}/${manifest.id}`} className="block">
                      {key === 'closedAt' ? formatDate(manifest[key]) : manifest[key] || '-'}
                    </Link>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ManifestTable
