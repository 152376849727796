import React, { useMemo, useState } from 'react'

export const AuthContext = React.createContext()

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(localStorage.getItem('ID') !== null)
  const [warehouseId, setWarehouseId] = useState(null)
  const [apiKey, setApiKey] = useState(null)

  const authState = useMemo(() => ({
    auth,
    setAuth,
    warehouseId,
    setWarehouseId,
    apiKey,
    setApiKey
  }), [auth, warehouseId, apiKey])

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
}

export default AuthProvider
