import React from 'react'

const Paginator = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1)
    }
  }

  return (
    <div className="mt-4 flex justify-center">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="mx-2 rounded bg-gray-300 px-4 py-2 disabled:bg-gray-400"
        type="button"
      >
        Anterior
      </button>
      <span className="mx-2 px-4 py-2">
        Página {currentPage} de {totalPages}
      </span>
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="mx-2 rounded bg-gray-300 px-4 py-2 disabled:bg-gray-400"
        type="button"
      >
        Siguiente
      </button>
    </div>
  )
}

export default Paginator
