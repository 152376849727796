import React, { useState } from 'react'

const PickManualProduct = ({ setPickProduct }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [barcode, setBarcode] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')

  const handleAddNewProduct = () => {
    if (!barcode || !name || !price) return

    const parsedPrice = parseFloat(price)
    const validPrice = !Number.isNaN(parsedPrice) && parsedPrice > 0 ? parsedPrice : 0

    setPickProduct((prevPickProduct) => [
      ...prevPickProduct,
      { barcode, name, price: validPrice, quantity: 1 }
    ])
    setBarcode('')
    setName('')
    setPrice('')
    setIsVisible(false)
  }

  const handleClose = () => {
    setIsVisible(false)
    setBarcode('')
    setName('')
    setPrice('')
  }

  const inputStyles =
    'w-full rounded-lg border border-gray-300 px-4 py-2 text-gray-700 placeholder-gray-400 shadow-sm focus:border-[#33cccc] focus:outline-none'

  return (
    <>
      <div className='mt-4 flex items-start'>
      <button
        type="button"
        onClick={() => setIsVisible(true)}
        className="w-auto rounded-lg bg-[#33cccc] px-4 py-2 text-white shadow-sm transition-colors duration-200
       hover:bg-[#2eb8b8]"
      >
        Agregar Producto Manual
      </button>
      </div>

      {isVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50">
          <div className="w-full max-w-md rounded-xl bg-white p-6 shadow-xl">
            <div className="mb-6 flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-800">Agregar Producto Manual</h2>
              <button
                type="button"
                onClick={handleClose}
                className="p-1 text-gray-400 hover:text-gray-600"
              >
                ✕
              </button>
            </div>

            <div className="space-y-4">
              <input
                type="text"
                value={barcode}
                className={inputStyles}
                onChange={(e) => setBarcode(e.target.value)}
                placeholder="Código de barras"
              />
              <input
                type="text"
                value={name}
                className={inputStyles}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nombre"
              />
              <input
                type="number"
                value={price}
                className={inputStyles}
                onChange={(e) => setPrice(e.target.value)}
                placeholder="Precio"
              />
            </div>

            <div className="mt-6 flex justify-end gap-3">
              <button
                type="button"
                onClick={handleClose}
                className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-700
               transition-colors duration-200 hover:bg-gray-50"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleAddNewProduct}
                className="rounded-lg bg-[#33cccc] px-4 py-2 text-white transition-colors
                duration-200 hover:bg-[#2eb8b8]"
              >
                Agregar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PickManualProduct
