import apiClient from '../../axiosConfig/apiClient'
import { DISTRIBUTION_RETURN_URL } from '../../constants/api'
import downloadLabel from '../../utils/downloadLabel'

export const createDistributionReturn = async (body) => {
  const response = await apiClient.post(DISTRIBUTION_RETURN_URL, body)
  return response.data
}

const buildDistributionReturnBody = (products, guideNumber) => {
  const totalCost = products.reduce((sum, product) => sum + product.price * product.quantity, 0)

  return {
    orderId: '',
    guideNumber,
    warehouseId: '',
    products: products.map((product) => ({
      code: product.barcode,
      name: product.name,
      price: product.price,
      photos: [''],
      weight: 1,
      quantity: product.quantity
    })),
    companyId: '',
    cost: totalCost,
    weight: 1,
    volume: 2
  }
}

const distributionReturnPackage = async (products, guideNumber) => {
  try {
    const data = await createDistributionReturn(buildDistributionReturnBody(products, guideNumber))

    const labelData = data?.label?.[0]
    const packageData = {
      order_id: labelData.order_id,
      pinflag_id: labelData.pinflag_id,
      tracking_number: labelData.tracking_number,
      label: labelData.labelUrl,
      courier: labelData.courier
    }
    downloadLabel(labelData.labelUrl)

    return packageData
  } catch (error) {
    return null
  }
}


export default distributionReturnPackage
