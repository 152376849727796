import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import Header from '../../components/Header'
import useFetch from '../../hooks/useFetch'
import { getManifestById } from '../../helpers/requests/manifest'
import downloadLabel from '../../utils/downloadLabel'
import {
  closedPackage,
  manifest as manifestIcon,
  checkWhite} from '../../constants/icons'
import SearchBar from '../../components/shared/SearchBar'
import ROUTES from '../../constants/routes'
import {
  PACKAGE_DELIVERED,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PROCESSED,
  PACKAGE_BOUGHT,
  PACKAGE_STATUS_MANIFEST
} from '../../constants/packageStatus'

const ManifestView = () => {
  const { manifestId } = useParams()
  const [manifest, setManifest] = useState({})
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedTab, setSelectedTab] = useState('Todos')
  const { isLoading } = useFetch(getManifestById, setManifest, manifestId)

  const formatDate = (dateString) =>
    new Date(dateString).toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit'
    })

  const filteredShippings = manifest.shippings?.filter(
    (shipping) =>
      shipping.package.orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      shipping.trackingNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
      shipping.package.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      shipping.package.packageStatus.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const countDeliveredPackages = (manifestsList) =>
    manifestsList.reduce((count, currentManifest) => {
      const deliveredCount = currentManifest.shippings.filter(
        (shipping) => shipping.package.packageStatus === PACKAGE_DELIVERED
      ).length
      return count + deliveredCount
    }, 0)

  const filterByTab = (tab) => {
    switch (tab) {
      case 'Exitoso':
        return filteredShippings?.filter(
          (shipping) => shipping.package.packageStatus === PACKAGE_BOUGHT
        )
      case 'Procesado':
        return filteredShippings?.filter(
          (shipping) => shipping.package.packageStatus === PACKAGE_PROCESSED
        )
      case 'Enviado':
        return filteredShippings?.filter(
          (shipping) => shipping.package.packageStatus === PACKAGE_ON_DELIVERY
        )
      case 'Entregado':
        return filteredShippings?.filter(
          (shipping) => shipping.package.packageStatus === PACKAGE_DELIVERED
        )
      default:
        return filteredShippings
    }
  }



  return (
    !isLoading && (
      <div className="w-full overflow-y-auto rounded-lg bg-gray-100">
        <Header to="/manifiestos" name="Manifiestos" color="bg-purple" qrScannerStyle />
        <div className="mx-auto w-11/12 bg-white px-6">
          <div className="mb-6 mt-20 flex items-center justify-between border-b pt-4">
            <div className="flex items-center gap-2">
              <h1 className="text-lg font-semibold">Manifiesto: {manifest.dynamicId}</h1>
              <span className="rounded-full bg-gray-100 py-1 px-2 text-sm text-gray-500">
                • {manifest.open === true ? 'Abierto' : 'Cerrado'}
              </span>
            </div>
            <button
              type="button"
              className="flex items-center gap-2 rounded-lg bg-teal-400 px-4 py-2 text-white"
              onClick={() => downloadLabel(manifest.pdfUrl)}
            >
              <img className="h-5 w-5 brightness-0 invert" src={manifestIcon} alt="manifest" />{' '}
              Descargar manifiesto
            </button>
          </div>

          <div className="mb-6 flex gap-6">
            <div className="flex flex-row gap-1 rounded-full bg-gray-100 py-1 px-2">
              <img className="h-5 w-5" src={closedPackage} alt="paquetes" />
              <span className="text-sm text-black">{manifest.shippings.length} paquetes</span>
            </div>
            <div className="flex items-center gap-2 rounded-full bg-gray-100 py-1 px-2">
              <img className="h-5 w-5 invert" src={checkWhite} alt="entregado" />
              <span className="text-sm text-black">
                {countDeliveredPackages([manifest])} entregados
              </span>
            </div>
          </div>

          <div className="mb-6 flex flex-row gap-4 text-sm text-gray-600">
            <div className="text-start">
              <p>Fecha cierre</p>
              <p>{formatDate(manifest.closedAt)}</p>
            </div>
            <div className="text-start">
              <p>Courier</p>
              <p>{manifest.courier}</p>
            </div>
          </div>

          <div className="mb-6 border-b">
            <div className="flex items-center justify-between">
              <div className="flex gap-4">
                {['Todos', 'Exitoso', 'Procesado', 'Enviado', 'Entregado'].map((tab) => (
                  <button
                    type="button"
                    key={tab}
                    className={`px-1 pb-2 ${
                      selectedTab === tab ? 'border-b-2 border-teal-400' : 'text-gray-600'
                    }`}
                    onClick={() => setSelectedTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className="flex justify-end">
                <SearchBar
                  placeholder="Buscar"
                  getter={searchQuery}
                  setter={setSearchQuery}
                  searcher={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </div>

          <div className="m-8 overflow-x-auto">
            <table className="w-full">
              <thead className="text-left text-sm text-gray-600">
                <tr className="border-b">
                  <th className="pb-2 text-left font-normal">
                    <input type="checkbox" />
                  </th>
                  <th className="pb-2 text-left font-normal">ID</th>
                  <th className="pb-2 text-left font-normal">O. de Transporte</th>
                  <th className="pb-2 text-left font-normal">Tienda</th>
                  <th className="pb-2 text-left font-normal">Estado</th>
                  <th className="pb-2 text-left font-normal">Fecha</th>
                  <th className="pb-2 text-left font-normal" />
                </tr>
              </thead>
              <tbody>
                {filterByTab(selectedTab)?.map((shipping) => (
                  <tr key={shipping.packageId} className="border-b">
                    <td className="py-3 text-left">
                      <input type="checkbox" />
                    </td>
                    <td className="py-3 text-left">{shipping.package.orderId}</td>
                    <td className="py-3 text-left">{shipping.trackingNumber}</td>
                    <td className="py-3 text-left">{shipping.package.companyName}</td>
                    <td className="py-3 text-left">
                      <div>
                        <span
                          className={`inline-flex items-center rounded-full px-2 py-1 text-sm ${
                            PACKAGE_STATUS_MANIFEST[shipping.package.packageStatus]?.style || 'bg-white'
                          }`}
                        >
                          <img
                            src={PACKAGE_STATUS_MANIFEST[shipping.package.packageStatus]?.icon}
                            alt={shipping.package.packageStatus}
                            className="mr-1 h-4 w-4"
                          />
                          {PACKAGE_STATUS_MANIFEST[shipping.package.packageStatus]?.text || '-'}
                        </span>
                      </div>
                    </td>
                    <td className="py-3 text-left">{formatDate(shipping.package.purchaseDate)}</td>
                    <td className="py-3 text-left">
                      <Link
                        to={`${ROUTES.MANIFESTS}/${manifestId}/${shipping.packageId}`}
                        className="block"
                      >
                        <button
                          type="button"
                        >
                          Ir
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  )
}

export default ManifestView
